import React from "react"
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Slide from "react-reveal/Slide";
import Layout from "../components/layout"
import Seo from "../components/seo"
import WeWorkWith from "../components/WeWorkWith"

const Industries = ({ data, location }) => {
  const { t, i18n } = useTranslation();

  const brand = (i18n.language === "tr" ? data.site?.siteMetadata?.titleTr : data.site?.siteMetadata?.titleEn) || `tbant`

  const schema = {
    "@context": "https://schema.org/",
    "@type": "QAPage",
    "mainEntity": {
      "@type": "Question",
      "name": t("industriesTitle"),
      "answerCount": "1",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": t("industriesDescription"),
        "author": {
          "@type": "Organization",
          "name": brand,
          "location": "Tuzla Deri OSB Vakum Cad. No:13/A\nÇ3-3 34957 Tuzla / İstanbul",
          "identifier": brand + "\n\nAdres\n\nTuzla Deri OSB Vakum Cad. No:13/A\nÇ3-3 34957 Tuzla / İstanbul\n\nE-mail\n\niletisim@tbantsan.com\n\nTelefon\n\n+90 216 394 83 70"
        },
        "dateCreated": "2022-06-10T21:00:00.000Z",
        "url": data.site.siteMetadata.siteUrl + location.pathname
      }
    }
  }

  const seo = {
    title: t("metaIndustriesTitle"),
    description: t("metaIndustriesDescription"),
    keywords: t("metaIndustriesKeywords"),
    schema: schema
  }

  let today = new Date();
  const lastUpdateDay = new Date(today.setDate(today.getDate() - 1)).toLocaleDateString("tr-TR");

  return (
    <Layout className="bg-reallyDarkBlue">
      <Seo {...seo} />

      <div className="bg-reallyDarkBlue text-white relative">
        <div className="max-w-7xl px-6 md:px-10 py-10 mx-auto">
          <div>
            <Slide bottom duration={1000}>
              <h1 className="text-4xl md:text-6xl px-10 md:max-w-5xl font-bold mx-auto text-center whitespace-pre-line">
                {t("industriesTitle")}
              </h1>
            </Slide>
            <p className="italic text-darkGray font-extralight text-sm text-center pt-5">{t("lastUpdate")} {lastUpdateDay} </p>
            <div className="w-full my-20">
              <StaticImage
                src="../images/industries-1.jpg"
                alt={t("industriesAltText")}
                formats={["auto", "webp", "avif"]}
              />
            </div>
          </div>
          <div className="md:py-24 max-w-xl mx-auto px-16 md:px-0">
            <Slide bottom duration={1000}>
              <p className="text-white text-lg font-light whitespace-pre-line">
                {t("industriesDescription")}
              </p>
            </Slide>
          </div>
        </div>
        <div className="relative">
          <div className="w-full my-20">
            <div className="absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4/5 md:px-10 mx-auto">
              <Slide bottom duration={1000}>
                <p className="text-white text-3xl md:text-6xl text-center font-bold ">{t("industriesImageText")}</p>
              </Slide>
            </div>
            <StaticImage
              src="../images/industries-2.jpg"
              alt={t("industriesAltText")}
              formats={["auto", "webp", "avif"]}
            />
          </div>
        </div>
        <WeWorkWith />
      </div>
    </Layout>
  )
}

export default Industries

export const query = graphql`
   query ($language: String!){
    locales: allLocale(filter: { language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTr
        titleEn
        siteUrl
      }
    }
  }
`